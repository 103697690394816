<template>
    <div class="centro">
      <v-card
        outlined
        id="cartao"
        class="mx-auto elevation-10 mb-2"
      >
        <v-card-title class="mb-10">
          <svg-icon height="50px" width="50px" type="mdi" :path="icone"></svg-icon>
          <h3 v-if="this.$route.fullPath == '/usuarios/new'">Cadastro de Usuários (Inclusão)</h3>
          <h3 v-else>Cadastro de Usuários (Alteração)</h3>
        </v-card-title>
        <form id="formulario">
          <v-row>
            <v-col cols="2">
              <v-text-field 
                id="id" 
                name="id"
                dense
                disabled
                v-model="usuario.id"
                label="ID"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="usuario_logado == '0'">
              <v-select
                dense
                v-model="usuario.id_cliente"
                :items="clientes"
                :item-text="getClienteText"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field 
                id="nome" 
                name="nome"
                dense
                v-model="usuario.nome"
                label="Nome"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field 
                id="cpf" 
                name="cpf"
                dense
                v-model="usuario.cpf"
                label="CPF"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field 
                id="senha" 
                name="senha"
                dense
                v-model="usuario.senha"
                @click:append="passwordVisibleToggle"
                :type="passwordVisible ? 'text' : 'password'"
                :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field 
                id="email" 
                name="email"
                dense
                v-model="usuario.email"
                label="Email"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </form>
        <v-btn class="botoes" color="blue darken-1" text @click="cancel"> Cancelar </v-btn>
        <v-btn class="botoes" color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-card>
    </div>
  </template>
  
  <script>
  import svcUsuarios from "../../services/svcUsuarios";
  import svcClientes from "../../services/svcClientes";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { decriptar, encriptar } from "../../config/cripto";
  import { mdiAccountGroup } from '@mdi/js';
  
  export default {
    components: {
          SvgIcon
      },
    data() {
      return {
        icone: mdiAccountGroup,
        usuario: {
          id: "",
          id_cliente: "",
          nome: "",
          cpf: "",
          email: "",
          senha: "",
        },
        clientes: [],
        cliente_user: "",
        usuario_logado: "",
        passwordVisible: false,
      };
    },
  
    methods: {
    passwordVisibleToggle() {
      this.passwordVisible = !this.passwordVisible;
    },

    getClienteText(item) {
      return item.nome;
    },

    lista_clientes() {
      svcClientes
        .listar()
        .then((resp) => {
          this.clientes = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    pesquisa(id) {
        svcUsuarios
          .pesquisar(id)
          .then((resp) => {
            this.usuario = resp.data;
            this.usuario.senha = decriptar(this.usuario.senha);
          })
          .catch((error) => {
            alert(error);
          });
      },
  
      cancel() {
        this.reposiciona();
      },
  
      save() {
        this.usuario.senha = encriptar(this.usuario.senha);
        if (this.$route.fullPath == "/usuarios/new") {
          svcUsuarios
            .incluir(this.usuario)
            .then((resp) => {
              if (resp.status == 200) {
                this.usuario = resp.data;
                this.reposiciona();
              } else {
                alert("Erro inserindo novo usuário.");
              }
            })
            .catch((error) => {
              alert(error);
            });
        } else {
            svcUsuarios
              .alterar(this.usuario)
              .then((resp) => {
                if (resp.status != 200) {
                  alert("Erro alterando dados do usuário.");
                }
              })
              .then(() => {
                this.reposiciona();
              })
              .catch((error) => {
                alert(error);
              });
        }
      },
  
      reposiciona() {
        this.$router.push("/usuarios");
      },
    },
  
    mounted() {
      this.usuario_logado = decriptar(localStorage.getItem("udi"));
      this.cliente_user = decriptar(localStorage.getItem("cdi"));
      this.lista_clientes();
      if (this.$route.fullPath != "/usuarios/new") {
        this.pesquisa(decriptar(this.$route.params.id))
      } else {
        this.usuario.id_cliente = this.cliente_user;
      }
    },
  };
  </script>
  
  <style scoped>
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1px;
  }
  label {
    font-weight: bold;
    margin-bottom: 1px;
    color: #222;
    text-align: left;
  }
  .botoes {
    margin-top: 5px;
  }
</style>
  