import { http } from './config'
import { encriptar } from '../config/cripto';

export default {
    listar: (id_cliente) => {
        return http.get(`/usuarios/listar/${encriptar(id_cliente)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    incluir: (usuario) => {
        const params = {
            usuario: usuario
        }
        return http.post('/usuarios/incluir', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    alterar: (usuario) => {
        const params = {
            usuario: usuario
        }
        return http.put('/usuarios/alterar', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    excluir: (id) => {
        return http.delete(`/usuarios/excluir/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisar: (id) => {
        return http.get(`/usuarios/pesquisar/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
}